import React from "react";
import { graphql, PageProps } from "gatsby";

import { PageLayout } from "src/components/layout/PageLayout";
import { Wysiwyg } from "src/components/Wysiwyg";

interface DeliveryReturnsPageProps extends PageProps {
  data: GatsbyTypes.DeliveryReturnsPageQuery;
}

const DeliveryReturnsPage: React.FC<DeliveryReturnsPageProps> = ({ data }) => {
  const pageContent = data.strapiDeliveryAndReturns?.content;

  return (
    <PageLayout title="Terms &amp; Conditions" width="normal">
      {pageContent && (
        <Wysiwyg content={pageContent} />
      )}
    </PageLayout>
  );
};

export default DeliveryReturnsPage;

export const query = graphql`
  query DeliveryReturnsPage {
    strapiDeliveryAndReturns {
      content
    }
  }
`;
