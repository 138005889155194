import React from "react";

import { Link } from "src/components/Link";
import { TextProps } from "src/components/Text";

export const getMarkdownTextProps = (paragraph: string): TextProps => {
  if (paragraph.startsWith("# ")) {
    return {
      as: "h1",
      color: "red"
    };
  } else if (paragraph.startsWith("## ")) {
    return {
      as: "span",
      bold: true
    };
  } else if (paragraph.startsWith("- ")) {
    return { as: "li" };
  } else {
    return { as: "p" };
  }
};

export const parseMarkdownLinks = (paragraph: string): Array<string | JSX.Element> => {
  const linkSplitRegex = /(\[[^\]]+\]\([^)]+\))/g;
  const linkRegex = /\[(.+)]\((.+)\)/;
  const internalLinkRegex = /^\//;

  // converts markdown URLs to <Link /> elements
  const contentWithLinks = paragraph
    // splits string around each markdown link
    .split(linkSplitRegex)
    // removes empty strings
    .filter(str => !!str)
    .map(str => {
      const linkData = str.match(linkRegex);

      if (!linkData) {
        return str;
      }

      const href = linkData[ 2 ];
      const target = !internalLinkRegex.test(href) ? "_blank" : undefined;

      return (
        <Link key={href} href={href} target={target} color="red">
          {linkData[ 1 ]}
        </Link>
      );
    });

  return contentWithLinks;
};

export const parseMarkdownItalics = (paragraphSections: Array<string | JSX.Element>): Array<string | JSX.Element> => {
  const italicsSplitRegex = /(\*[^*]+\*)/g;
  const italicsRegex = /\*([^*]+)\*/;

  return paragraphSections.flatMap(section => {
    if (typeof section !== "string") {
      return section;
    }

    // wraps italics with <i> tags
    return section
      // splits string around each italics block
      .split(italicsSplitRegex)
      // removes empty strings
      .filter(str => !!str)
      .map(str => {
        const italicsMatch = str.match(italicsRegex);

        if (!italicsMatch) {
          return str;
        }

        const content = italicsMatch[ 1 ];

        return (
          <i key={content}>
            {content}
          </i>
        );
      });
  });
};