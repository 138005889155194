import React from "react";
import styled from "styled-components";

import {
  getMarkdownTextProps, parseMarkdownItalics, parseMarkdownLinks
} from "src/utils/markdown";
import { breakpoint } from "src/utils/media-query";

import { Text } from "./Text";

interface WysiwygProps {
  className?: string;
  content: string;
}

export const Wysiwyg: React.FC<WysiwygProps> = ({ className, content }) => {
  // split markdown into paragraphs
  const paragraphs = content.split(/\n+/);

  // apply formatting
  const FormattedParagraphs = paragraphs.map((paragraph, i) => {
    const textProps = getMarkdownTextProps(paragraph);
    // remove markdown title markers
    const content = paragraph.replace(/#+ /, "").replace(/- /, "");
    // make links clickable
    const contentWithLinks = parseMarkdownLinks(content);
    // stylise markdown italics
    const contentWithLinksAndItalics = parseMarkdownItalics(contentWithLinks);

    return (
      <Text {...textProps} key={`${textProps.as}-${i + 1}`}>
        {contentWithLinksAndItalics}
      </Text>
    );
  });

  return (
    <ListWrapper>
      <TextWrapper className={className}>
        {FormattedParagraphs}
      </TextWrapper>
    </ListWrapper>
  );
};

const ListWrapper = styled.ul`
  list-style-type: initial;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 1rem;

  ${breakpoint("tabletLL")} {
    margin: 4rem 0;
  }
   
  h1 {
    margin-bottom: 1rem;
  }
  span {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  li {
    margin-left: 2rem;
  }
  p + p {
    margin-top: 1rem;
  }
  p + li, li + p {
    margin-top: 1rem;
  }
  i {
    font-style: italic;
  }
`;